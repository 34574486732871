type Props = {
	max: number;
	min?: number;
	integer?: boolean;
};

const getRandom = ({ max, min = 0, integer = true }: Props) => {
	const rand = min + Math.random() * (max - min);
	return integer ? Math.round(rand) : rand;
};

export default getRandom;
